import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DeveloperAnalyticsTable from "./views/DeveloperAnalyticsTable";
import RawAnalyticsTable from "./views/RawAnalyticsTable";
import UsageAnalyticsTable from "./views/UsageAnalyticsTable";
import { MainTheme } from "./theme";
import LeftDrawer, { DrawerHeader } from "./components/LeftDrawer";
import TopAppBar from "./components/TopAppBar";
import { DrawerMenuItems, UserData } from "./model";
import SignIn from "./components/SignIn";
import Network from "./network";
import UserManagementTable from "./views/UserManagementTable";
import RejoyceSessionsLaunches from "./views/RejoyceSessionsLaunches";
import { LocalizationProvider } from "@mui/x-date-pickers";
import GameSessionLaunches from "./views/GameSessionsLaunches";
import PatientAnalytics from "./views/PatientAnalytics";
import PreliviaCostCalculatorApp from "./PreliviaCostCalculator/PreliviaCostCalculatorApp";
import PreliviaCostCalcTable from "./views/PreliviaCostCalcTable";

export default function App() {
  const [userData, setUserData] = React.useState<UserData | null>(null);

  const [open, setOpen] = React.useState(false);
  const [selectedView, setSelectedView] = React.useState<DrawerMenuItems>(
    DrawerMenuItems.RejoyceSessions
  );

  const [displayPreliviaCostCalc, setDisplayPreliviaCostCalc] = React.useState(false);

  const hasBeenCalled = React.useRef(false);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    if (window.location.href.includes('prelivia_cost_calc')) {
      setDisplayPreliviaCostCalc(true);
      return;
    }

    const resp = await Network.Get("auth/userinfo");
    if (resp.status === 200) {
      setUserData(resp.data);
    }
    setPageLoaded(true);
  };

  React.useEffect(() => {
    constructor();
  }, [userData]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={MainTheme}>
        {displayPreliviaCostCalc ? <PreliviaCostCalculatorApp/> : pageLoaded &&
          (userData ? (
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <TopAppBar
                open={open}
                handleDrawerOpen={() => setOpen(true)}
                text={selectedView.toString()}
                userData={userData}
                setUserData={setUserData}
                setSelectedView={setSelectedView}
              />
              <LeftDrawer
                open={open}
                handleDrawerClose={() => setOpen(false)}
                setSelectedView={setSelectedView}
              />
              <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <DrawerHeader />
                {selectedView === DrawerMenuItems.RejoyceSessions ? (
                  <RejoyceSessionsLaunches />
                ) : selectedView === DrawerMenuItems.GameSessions ? (
                  <GameSessionLaunches />
                ) : selectedView === DrawerMenuItems.PatientAnalytics ? (
                  <PatientAnalytics />
                ) : selectedView === DrawerMenuItems.DeveloperAnalytics ? (
                  <DeveloperAnalyticsTable />
                ) : selectedView === DrawerMenuItems.UsageAnalytics ? (
                  <UsageAnalyticsTable />
                ) : selectedView === DrawerMenuItems.RawData ? (
                  <RawAnalyticsTable />
                ) : selectedView === DrawerMenuItems.PreliviaCostCalc ? (
                  <PreliviaCostCalcTable />
                ) : (
                  <UserManagementTable
                    userData={userData}
                    setUserData={setUserData}
                  />
                )}
              </Box>
            </Box>
          ) : (
            <SignIn setUserData={setUserData} />
          ))}
      </ThemeProvider>
    </LocalizationProvider>
  );
}
