import React from "react";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import { Box, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useWindowSize } from "../tools";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";

function EditToolbar({
  selectionType,
  setSelectionType,
  monthRangeDate,
  setMonthRangeDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ...props
}) {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectionType: string
  ) => {
    setSelectionType(selectionType);
  };

  return (
    <GridToolbarContainer>
      <GridToolbar sx={{ flexGrow: 1 }} />
      <Box sx={{ display: selectionType === "month" ? "inline" : "none" }}>
        <DatePicker
          openTo="month"
          views={["year", "month"]}
          label="Selected month"
          value={monthRangeDate}
          maxDate={moment()}
          onChange={(newValue) => {
            setMonthRangeDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              sx={{ mr: 2 }}
              variant="standard"
              size="small"
              {...params}
              helperText={null}
            />
          )}
        />
      </Box>
      <Box sx={{ display: selectionType === "custom" ? "inline" : "none" }}>
        <DatePicker
          label="Start date"
          value={startDate}
          maxDate={endDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              sx={{ mr: 2 }}
              variant="standard"
              size="small"
              {...params}
              helperText={null}
            />
          )}
        />
        <DatePicker
          label="End date"
          value={endDate}
          maxDate={moment()}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              sx={{ mr: 2 }}
              variant="standard"
              size="small"
              {...params}
              helperText={null}
            />
          )}
        />
      </Box>
      <ToggleButtonGroup
        sx={{ ml: 3 }}
        color="secondary"
        value={selectionType}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton value="month">Month</ToggleButton>
        <ToggleButton value="custom">Custom</ToggleButton>
      </ToggleButtonGroup>
    </GridToolbarContainer>
  );
}

export default function RangeDataGrid({ columns, rows, loadRange, ...props }) {
  const [, height] = useWindowSize();

  const [selectionType, setSelectionType] = React.useState("month");

  const [monthRangeDate, setMonthRangeDate] = React.useState<Moment>(moment());
  const [startDate, setStartDate] = React.useState<Moment>(
    moment().add(-30, "days")
  );
  const [endDate, setEndDate] = React.useState<Moment>(moment());

  const loadMonth = async (date: Moment) => {
    let startDate = new Date(date.year(), date.month(), 1);
    let endDate = new Date(date.year(), date.month() + 1, 0);
    loadRange(startDate, endDate);
  };

  React.useEffect(() => {
    switch (selectionType) {
      case "month":
        loadMonth(monthRangeDate);
        break;
      case "custom":
        loadRange(
          new Date(startDate.year(), startDate.month(), startDate.date()),
          new Date(endDate.year(), endDate.month(), endDate.date())
        );
        break;
    }
  }, [selectionType, monthRangeDate, startDate, endDate]);

  return (
    <Box sx={{ textAlign: "center" }}>
      {rows ? (
        <Box>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: {
                selectionType,
                setSelectionType,
                monthRangeDate,
                setMonthRangeDate,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
              },
            }}
            sx={{
              height: height * 0.75,
              boxShadow: 3,
            }}
          />
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}
