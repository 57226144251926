import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import Network from "../network";
import { AnalyticsEntryInterface } from "../model";
import RangeDataGrid from "../components/RangeDataGrid";

interface PatientAnalyticsEntry {
  id: number;
  license_key: string;
  action: number;
  diagnoses_primary: string[];
  diagnoses_secondary: string[];
  date: string;
  time: string;
}

const columns: GridColDef[] = [
  { field: "action", headerName: "Action", minWidth: 200 },
  { field: "diagnoses_primary", headerName: "Diagnoses Primary", flex: 1 },
  { field: "diagnoses_secondary", headerName: "Diagnoses Secondary", flex: 1 },
  {
    field: "time",
    headerName: "Time",
    description: "Local time of data received by server",
  },
  {
    field: "date",
    headerName: "Date",
    description: "Local date of data received by server",
  },
];

export default function PatientAnalytics() {
  const [rows, setRows] = React.useState<PatientAnalyticsEntry[] | null>(null);

  const loadRange = async (startDate: Date, endDate: Date) => {
    const resp = await Network.Get(
      `analytics/patients?start_date_iso=${startDate.toISOString()}&end_date_iso=${endDate.toISOString()}`
    );
    if (resp.status === 200) {
      let data = resp.data as AnalyticsEntryInterface[];
      data = data.reverse();

      let entries: PatientAnalyticsEntry[] = [];

      for (let i = 0; i < data.length; ++i) {
        let date = new Date(data[i].timestamp);
        entries.push({
          id: entries.length,
          license_key: data[i].data.license_key,
          diagnoses_primary: data[i].data.data.data.diagnosis_primary,
          diagnoses_secondary: data[i].data.data.data.diagnosis_secondary,
          action: data[i].data.data.action,
          date: date.toLocaleDateString(),
          time: date.toLocaleTimeString(),
        });
      }

      setRows(entries);
    }
  };

  return <RangeDataGrid rows={rows} loadRange={loadRange} columns={columns} />;
}
