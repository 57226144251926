import * as React from "react";
import { TextField } from "@mui/material";

function ValidatedTextField({ errorText, label, value, onChange, ...props }) {
  return (
    <TextField
      size="small"
      margin="normal"
      fullWidth
      error={errorText !== ""}
      helperText={errorText === "" && props.infoText !== undefined ? props.infoText : errorText}
      value={value}
      onChange={onChange}
      label={label}
      {...props}
    />
  );
}

export default ValidatedTextField;
