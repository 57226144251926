export interface AnalyticsEntryInterface {
  ip: string;
  data: any;
  timestamp: string;
}

export interface AnalyticsChunkInterface {
  total_row_count: number;
  current_row_count: number;
  rows: AnalyticsEntryInterface[];
}

export interface UserData {
  uuid: string;
  name: string;
  email: string;
}

export interface DisplayUserData {
  isNew: boolean;
  id: string;
  uuid: string;
  name: string;
  email: string;
}

export interface PreliviaCostCalcEntryInterface {
  data: any;
  timestamp: string;
}

export enum DrawerMenuItems {
  RejoyceSessions = "Rejoyce Sessions",
  GameSessions = "Game Sessions",
  PatientAnalytics = "Patient Analytics",
  DeveloperAnalytics = "Developer Analytics",
  UsageAnalytics = "Usage Analytics",
  RawData = "Raw Data",
  UserManagement = "User Management",
  PreliviaCostCalc = "Prelivia Cost Calculator"
}
