import axios from "axios";
import Logger from "./logger";

export interface NetworkResponseInterface {
  data: any | null;
  status: number;
}

export default class Network {
  static timeout: number = 10000;

  public static async Get(
    request: string,
    params: any = null
  ): Promise<NetworkResponseInterface> {
    try {
      const result = await axios.get(request, {
        params: params,
        timeout: Network.timeout,
      });
      return { data: result.data, status: result.status };
    } catch (err: any) {
      Logger.Log(`request ${request} failed with ${err}`);
      if (err.response)
        return { data: err.response.data, status: err.response.status };
      else {
        alert("noConnectionToServer");
        return { data: null, status: 504 };
      }
    }
  }

  public static async Post(
    request: string,
    data: any
  ): Promise<NetworkResponseInterface> {
    try {
      const result = await axios.post(request, data, {
        timeout: Network.timeout,
      });
      return { data: result.data, status: result.status };
    } catch (err: any) {
      Logger.Log(`request ${request} failed with ${err}`);
      if (err.response)
        return { data: err.response.data, status: err.response.status };
      else {
        alert("noConnectionToServer");
        return { data: null, status: 504 };
      }
    }
  }

  public static async Auth(
    username: string,
    password: string
  ): Promise<NetworkResponseInterface> {
    try {
      const result = await axios.get("/auth/sign_in", {
        timeout: Network.timeout,
        auth: { username, password },
      });
      return { data: result.data, status: result.status };
    } catch (err: any) {
      Logger.Log(`request 'sign_in' failed with ${err}`);
      if (err.response) return { data: null, status: err.response.status };
      else {
        alert("noConnectionToServer");
        return { data: null, status: 504 };
      }
    }
  }
}
