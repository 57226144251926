import * as React from "react";

export function useWindowSize() {
  const [size, setSize] = React.useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function secondsToTimeString(totalSeconds: number) {
  let hours = Math.floor(totalSeconds / 60 / 60);
  let minutes = Math.floor(totalSeconds / 60) - hours * 60;
  let seconds = Math.floor(totalSeconds) - minutes * 60 - hours * 60 * 60;

  let result = "";
  if (hours > 0) result += hours + " h ";

  result += minutes + " min";

  result += " " + seconds + " sec";

  return result;
}
