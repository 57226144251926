import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

export const MainTheme = createTheme({
  palette: {
    primary: {
      main: "#3696d2",
    },
    secondary: {
      main: "#fbaa19",
    },
  },
});
