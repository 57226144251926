import React from "react";
import { Container, Typography } from "@mui/material";
import { QuestionnaireSimplifiedStateInterface } from "./questionnaireState";
import PreliviaQuestionnaireSimplified from "./PreliviaQuestionnaireSimplified";

export default function PreliviaCostCalculatorApp() {
  const [questionnaireStateObj, setQuestionnaireStateObj] =
    React.useState<QuestionnaireSimplifiedStateInterface | null>(null);
  const [dataSubmitted, setDataSubmitted] = React.useState(false);

  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!dataSubmitted ? (
        <PreliviaQuestionnaireSimplified
          questionnaireStateObj={questionnaireStateObj}
          setQuestionnaireStateObj={setQuestionnaireStateObj}
          setDataSubmitted={setDataSubmitted}
        />
      ) : (
        <Typography variant="h4" style={{verticalAlign: 'center', marginTop: 100, color: '#424242'}}>{"Facility acquired pressure injuries could be costing you "}
          <span style={{color: 'blue', fontSize: 38}}>{questionnaireStateObj!.estCostsByIncidenceFormatted}</span>{". This calculation is based on the 3.6% incidence rate (average for pressure injuries)."}
          <br/><br/>{"A detailed report has been emailed to you to the email address provided."}
          <br/><br/><br/>{"Learn how Prelivia can help reduce costs while improving care quality."}
        </Typography>
        // <PreliviaDataPlot
        //   questionnaireStateObj={questionnaireStateObj!}
        //   setDataSubmitted={setDataSubmitted}
        // />
      )}
    </Container>
  );
}
