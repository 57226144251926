import * as Regexes from "./regexes";

export function passwordValidator(password: string) {
  if (!password) return "Field can't be empty";
  if (Regexes.password.test(password))
    // Matching incorrect passwords here
    return "Password must be at least 8 characters long and contain at least one upper and lower case letters.";
  return "";
}

export function passwordChangeValidator(
  password: string,
  passwordNew: string,
  passwordNew2: string
) {
  if (passwordNew !== passwordNew2) return "Passwords don't match";
  if (password === passwordNew) return "Cannot use same password";
  return "";
}

export function emailValidator(email: string) {
  if (!email) return "Field can't be empty";
  if (!Regexes.email.test(email)) return "Incorrect email address format";
  return "";
}

export function nameValidator(name: string) {
  if (!name) return "Field can't be empty";

  if (name.length < 3 || name.length > 30)
    return "Name must be 3 to 30 characters";

  if (!Regexes.name.test(name)) return "Incorrect data format";
  return "";
}
