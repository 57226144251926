import React from "react";
import { Button, Card, Divider, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";
import { BeliefState } from "./belief";
import BeliefRow from "./BeliefRow";
import { QuestionnaireStateInterface } from "./questionnaireState";

export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - Number.parseInt(i))
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card
        sx={{
          backgroundColor: "#F9FEFF",
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 2,
          textAlign: "center",
        }}
      >
        <b>
          <p>{label}</p>
        </b>
        <b>${formatMoney(payload[payload.length - 1].value)}</b>
      </Card>
    );
  }

  return null;
};

interface PreliviaDataPlotProps {
  questionnaireStateObj: QuestionnaireStateInterface;
  setDataSubmitted: any;
}

export default function PreliviaDataPlot({
  questionnaireStateObj,
  setDataSubmitted,
}: PreliviaDataPlotProps) {
  const data = [
    {
      name: "Annual Cost Without Prelivia",
      acnp: questionnaireStateObj.estCostsByIncidence,
    },
    {
      name: "Cost of Prelivia",
      cp: questionnaireStateObj.costOfPrelivia,
      acnpi: questionnaireStateObj.estCostsByIncidence,
    },
    {
      name: "Total Cost Savings",
      acwpi: questionnaireStateObj.piRelatedCostsWPrelivia,
      tpicc: questionnaireStateObj.totalPIcostsCaptured,
    },
    {
      name: "Annual Cost With Prelivia",
      acwp: questionnaireStateObj.piRelatedCostsWPrelivia,
    },
  ];

  const beliefsData = [
    {
      yes: questionnaireStateObj.qualitiveFactors.filter(
        (belief) => belief.state === BeliefState.eYes
      ).length,
      maybe: questionnaireStateObj.qualitiveFactors.filter(
        (belief) => belief.state === BeliefState.eMaybe
      ).length,
      no: questionnaireStateObj.qualitiveFactors.filter(
        (belief) => belief.state === BeliefState.eNo
      ).length,
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        style={{ marginBottom: 20, textAlign: "center" }}
      >
        {`Prelivia Value Case Summary for ${questionnaireStateObj.facilityName}`}
      </Typography>
      <Divider style={{ height: 2, backgroundColor: "black" }} />
      <Typography variant="h6" style={{ paddingLeft: 30 }}>
        Estimated Pressure Injury Cost-Savings (Direct Expenses)
      </Typography>
      <Divider style={{ height: 2, backgroundColor: "black" }} />
      <Typography variant="h6" style={{ textAlign: "center", margin: 20 }}>
        Projected Net Annual Impact
      </Typography>
      <BarChart
        width={1000}
        height={500}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} stroke="#4472C4" />
        <XAxis
          dataKey="name"
          type="category"
          tickLine={false}
          tick={({ x, y, stroke, payload }) => (
            <text
              x={x}
              y={y + 15}
              style={{ fontWeight: "bold" }}
              textAnchor="middle"
            >{`${payload.value}`}</text>
          )}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          width={100}
          tickCount={
            Math.ceil(
              (questionnaireStateObj.estCostsByIncidence +
                questionnaireStateObj.costOfPrelivia) /
                500000
            ) + 1
          }
          tickFormatter={(value, index) => "$" + formatMoney(value)!}
          type="number"
        />
        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip active={active} payload={payload} label={label} />
          )}
        />
        <Bar dataKey="acnp" stackId="a" fill="#002060">
          <LabelList
            dataKey="acnp"
            position="top"
            style={{ fill: "black", fontWeight: "bold" }}
            formatter={(value) => "$" + formatMoney(value)!}
            offset={10}
          />
        </Bar>
        <Bar dataKey="acnpi" stackId="a" fill="#00000000" />
        <Bar dataKey="cp" stackId="a" fill="#FFC000">
          <LabelList
            dataKey="cp"
            position="top"
            style={{ fill: "black", fontWeight: "bold" }}
            formatter={(value) => "$" + formatMoney(value)!}
            offset={10}
          />
        </Bar>
        <Bar dataKey="acwp" stackId="a" fill="#002060">
          <LabelList
            dataKey="acwp"
            position="top"
            style={{ fill: "black", fontWeight: "bold" }}
            formatter={(value) => "$" + formatMoney(value)!}
            offset={10}
          />
        </Bar>
        <Bar dataKey="acwpi" stackId="a" fill="#00000000" />
        <Bar dataKey="tpicc" stackId="a" fill="#548235">
          <LabelList
            dataKey="tpicc"
            position="top"
            style={{ fill: "black", fontWeight: "bold" }}
            formatter={(value) => "$" + formatMoney(value)!}
            offset={10}
          />
        </Bar>
      </BarChart>
      <div style={{ marginTop: 100 }}>
        <table
          style={{
            fontWeight: "bold",
          }}
        >
          <tr>
            <td style={{ textAlign: "right", paddingRight: 100 }}>
              Annual Cost Without Prelivia
            </td>
            <td>$</td>
            <td style={{ paddingLeft: 10, textAlign: "right" }}>
              {formatMoney(questionnaireStateObj.estCostsByIncidence)}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "right", paddingRight: 100 }}>
              Annual Cost with Prelivia
            </td>
            <td>$</td>
            <td style={{ paddingLeft: 10, textAlign: "right" }}>
              {formatMoney(questionnaireStateObj.piRelatedCostsWPrelivia)}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Divider style={{ backgroundColor: "black" }} />
            </td>
          </tr>
          <tr style={{ color: "#548235" }}>
            <td style={{ textAlign: "right", paddingRight: 100 }}>
              Total Cost Savings
            </td>
            <td>$</td>
            <td style={{ paddingLeft: 10, textAlign: "right" }}>
              {formatMoney(questionnaireStateObj.totalPIcostsCaptured)}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Divider style={{ backgroundColor: "black" }} />
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "right", paddingRight: 100 }}>
              Cost of Prelivia
            </td>
            <td>$</td>
            <td style={{ paddingLeft: 10, textAlign: "right" }}>
              {formatMoney(questionnaireStateObj.costOfPrelivia)}
            </td>
          </tr>
        </table>
      </div>
      <Typography variant="h6" style={{ marginTop: 70 }}>
        Value Score
      </Typography>
      <Divider
        style={{ height: 2, marginBottom: 20, backgroundColor: "black" }}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginLeft: 30 }}>
          <BarChart
            width={150}
            height={270}
            data={beliefsData}
            margin={{
              top: -50,
            }}
          >
            <Bar dataKey="yes" stackId="a" fill="#92D050">
              <LabelList
                dataKey="yes"
                position="center"
                style={{ fill: "black", fontWeight: "bold" }}
                formatter={(value) => "Yes, " + value}
              />
            </Bar>
            <Bar dataKey="maybe" stackId="a" fill="#FFC000">
              <LabelList
                dataKey="maybe"
                position="center"
                style={{ fill: "black", fontWeight: "bold" }}
                formatter={(value) => "Maybe, " + value}
              />
            </Bar>
            <Bar dataKey="no" stackId="a" fill="#FF0000">
              <LabelList
                dataKey="no"
                position="center"
                style={{ fill: "black", fontWeight: "bold" }}
                formatter={(value) => "No, " + value}
              />
            </Bar>
          </BarChart>
        </div>
        <div style={{ marginLeft: 70 }}>
          <Typography style={{ fontWeight: "bold" }}>You believe…</Typography>
          {questionnaireStateObj.qualitiveFactors.map((belief, idx) => (
            <BeliefRow
              key={idx}
              idx={idx}
              text={belief.text}
              state={belief.state}
            />
          ))}
        </div>
      </div>
      <Divider
        style={{
          height: 1,
          marginTop: 20,
          backgroundColor: "black",
        }}
      />
      <Button
        startIcon={<ArrowBackIcon style={{ marginTop: -2 }} />}
        onClick={() => setDataSubmitted(false)}
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Edit Answers
      </Button>
    </>
  );
}
