import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import Network from "../network";
import { secondsToTimeString } from "../tools";
import { AnalyticsEntryInterface } from "../model";
import RangeDataGrid from "../components/RangeDataGrid";

interface GameSession {
  start: Date;
  end: Date | null;
}

interface GameSessionLaunchesEntry {
  id: number;
  ip: string;
  license_key: string;
  game_name: string;
  difficulty: string[];
  launch_count: number;
  sessions: GameSession[];
  avg_session: string;
  max_session: string;
  min_session: string;
}

const columns: GridColDef[] = [
  {
    field: "ip",
    headerName: "IP Address",
    description: "Source IP address of the ReHab platform machine",
    minWidth: 120,
  },
  { field: "license_key", headerName: "License Key", minWidth: 240, flex: 1 },
  { field: "game_name", headerName: "Game", minWidth: 110 },
  { field: "difficulty", headerName: "Difficulty", minWidth: 110 },
  { field: "launch_count", headerName: "Launch Count", minWidth: 110 },
  { field: "avg_session", headerName: "Average Session", minWidth: 140 },
  { field: "max_session", headerName: "Longest Session", minWidth: 140 },
  { field: "min_session", headerName: "Shortest Session", minWidth: 140 },
];

export default function GameSessionLaunches() {
  const [rows, setRows] = React.useState<GameSessionLaunchesEntry[] | null>(
    null
  );

  const loadRange = async (startDate: Date, endDate: Date) => {
    const resp = await Network.Get(
      `analytics/games_launches?start_date_iso=${startDate.toISOString()}&end_date_iso=${endDate.toISOString()}`
    );
    if (resp.status === 200) {
      let data = resp.data as AnalyticsEntryInterface[];

      let entries: GameSessionLaunchesEntry[] = [];

      if (data.length > 0) {
        entries.push({
          id: entries.length,
          ip: data[0].ip,
          license_key: data[0].data.license_key,
          game_name: "",
          difficulty: [],
          launch_count: 0,
          sessions: [],
          avg_session: "",
          min_session: "",
          max_session: "",
        });
      }

      for (let i = 0; i < data.length; ++i) {
        if (
          data[i].data.license_key !== entries[entries.length - 1].license_key
        ) {
          entries.push({
            id: entries.length,
            ip: data[i].ip,
            license_key: data[i].data.license_key,
            game_name: "",
            difficulty: [],
            launch_count: 0,
            sessions: [],
            avg_session: "",
            min_session: "",
            max_session: "",
          });
        }

        if (data[i].data.data.action === "game launched") {
          if (
            entries[entries.length - 1].game_name !== "" &&
            entries[entries.length - 1].game_name !==
              data[i].data.data.data.game
          ) {
            entries.push({
              id: entries.length,
              ip: data[i].ip,
              license_key: data[i].data.license_key,
              game_name: "",
              difficulty: [],
              launch_count: 0,
              sessions: [],
              avg_session: "",
              min_session: "",
              max_session: "",
            });
          }

          entries[entries.length - 1].game_name = data[i].data.data.data.game;

          if (
            entries[entries.length - 1].difficulty.indexOf(
              data[i].data.data.data.difficulty
            ) === -1
          )
            entries[entries.length - 1].difficulty.push(
              data[i].data.data.data.difficulty
            );
          entries[entries.length - 1].launch_count++;
          entries[entries.length - 1].sessions.push({
            start: new Date(data[i].data.timestamp),
            end: null,
          });
        } else if (
          data[i].data.data.action === "game quit" &&
          entries[entries.length - 1].game_name === data[i].data.data.data.game
        ) {
          entries[entries.length - 1].sessions[
            entries[entries.length - 1].sessions.length - 1
          ].end = new Date(data[i].data.timestamp);
        }
      }

      for (let i = 0; i < entries.length; ++i) {
        let avgTimeSec = 0;
        let minTimeSec = Number.MAX_VALUE;
        let maxTimeSec = 0;
        for (let j = 0; j < entries[i].sessions.length; ++j) {
          if (entries[i].sessions[j].end !== null) {
            let duractionSec =
              (entries[i].sessions[j].end!.valueOf() -
                entries[i].sessions[j].start.valueOf()) /
              1000;

            if (minTimeSec > duractionSec) minTimeSec = duractionSec;

            if (maxTimeSec < duractionSec) maxTimeSec = duractionSec;

            avgTimeSec += duractionSec;
          }
        }
        if (avgTimeSec !== 0)
          entries[i].avg_session = secondsToTimeString(
            avgTimeSec / entries[i].sessions.length
          );
        else entries[i].avg_session = "-";

        if (minTimeSec !== Number.MAX_VALUE)
          entries[i].min_session = secondsToTimeString(minTimeSec);
        else entries[i].min_session = "-";

        if (maxTimeSec !== 0)
          entries[i].max_session = secondsToTimeString(maxTimeSec);
        else entries[i].max_session = "-";
      }

      setRows(entries);
    }
  };

  return <RangeDataGrid rows={rows} loadRange={loadRange} columns={columns} />;
}
