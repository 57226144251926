import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { AnalyticsEntryInterface } from "../model";
import AnalyticsDataGrid from "../components/AnalyticsDataGrid";

interface DeveloperAnalyticsTableEntryInterface {
  id: number;
  ip: string;
  license_key: string;
  app_id: string;
  action: string;
  action_data: string;
  os_type: string;
  data: any;
  date: string;
  time: string;
}

const columns: GridColDef[] = [
  {
    field: "ip",
    headerName: "IP Address",
    description: "Source IP address of the ReHab platform machine",
    minWidth: 120,
  },
  { field: "license_key", headerName: "License Key", minWidth: 240 },
  {
    field: "app_id",
    headerName: "License Type",
    description: "Type of license issued",
    minWidth: 190,
  },
  { field: "action", headerName: "Action Type", minWidth: 190 },
  { field: "action_data", headerName: "Action Data", flex: 1, editable: true },
  { field: "os_type", headerName: "Windows Version", minWidth: 150 },
  {
    field: "time",
    headerName: "Time",
    description: "Local time of data received by server",
  },
  {
    field: "date",
    headerName: "Date",
    description: "Local date of data received by server",
  },
];

export default function DeveloperAnalyticsTable() {
  const [analyticsData, setAnalyticsData] = React.useState<
    DeveloperAnalyticsTableEntryInterface[] | null
  >(null);

  const formatPageDataOnLoad = (items: AnalyticsEntryInterface[]) => {
    let counter = 1;
    let tableEntries: DeveloperAnalyticsTableEntryInterface[] = [];
    items.forEach((item) => {
      let date = new Date(item.data.timestamp);
      let entry: DeveloperAnalyticsTableEntryInterface = {
        id: counter++,
        ip: item.ip,
        license_key: item.data.license_key,
        action: item.data.data.action,
        action_data: item.data.data.data
          ? JSON.stringify(item.data.data.data)
          : "",
        app_id: item.data.app_id,
        os_type: item.data.os_info.edition,
        data: JSON.stringify(item.data),
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
      };
      tableEntries.push(entry);
    });
    setAnalyticsData(tableEntries);
  };

  return (
    <AnalyticsDataGrid
      analyticsData={analyticsData}
      formatPageDataOnLoad={formatPageDataOnLoad}
      columns={columns}
    />
  );
}
