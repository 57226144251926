import * as React from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { PreliviaCostCalcEntryInterface } from "../model";
import { useWindowSize } from "../tools";
import Network from "../network";
import { Box } from "@mui/material";
import { formatMoney } from "../PreliviaCostCalculator/PreliviaDataPlot";

interface RawPreliviaCostCalcTableEntryInterface {
  id: number;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  facilityName: string;
  residents: number;
  lengthOfStay: number;
  estCostsByIncidence: string;
  date: string;
  time: string;
}

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "surname",
    headerName: "Surname",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 120
  },
  {
    field: "facilityName",
    headerName: "Facility",
    flex: 1,
  },
  {
    field: "residents",
    headerName: "Patients",
    width: 70
  },
  {
    field: "lengthOfStay",
    headerName: "LoS",
    width: 50
  },
  {
    field: "estCostsByIncidence",
    headerName: "Annual Cost Without Prelivia",
    width: 130
  },
  {
    field: "time",
    headerName: "Time",
    description: "Local time of data received by server",
  },
  {
    field: "date",
    headerName: "Date",
    description: "Local date of data received by server",
  },
];

export default function PreliviaCostCalcTable() {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;
    loadDataPage();
  };

  React.useEffect(() => {
    constructor();
  });

  const loadDataPage = async () => {
    setIsLoading(true);
    const resp = await Network.Get(
      `prelivia_cost_calc_api`
    );
    if (resp.status === 200) {
      let rows = resp.data as PreliviaCostCalcEntryInterface[];
      formatPageDataOnLoad(rows);
    }
    setIsLoading(false);
  };

  const [analyticsData, setAnalyticsData] = React.useState<
  RawPreliviaCostCalcTableEntryInterface[] | null
  >(null);

  const formatPageDataOnLoad = (items: PreliviaCostCalcEntryInterface[]) => {
    let counter = 1;
    let tableEntries: RawPreliviaCostCalcTableEntryInterface[] = [];
    items.forEach((item) => {
      let date = new Date(item.timestamp);
      let entry: RawPreliviaCostCalcTableEntryInterface = {
        id: counter++,
        name: item.data.name,
        surname: item.data.surname,
        email: item.data.email,
        mobile: item.data.mobile,
        facilityName: item.data.facilityName,
        residents: item.data.residents,
        lengthOfStay: item.data.lengthOfStay,
        estCostsByIncidence: "$ " + formatMoney(item.data.estCostsByIncidence),
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
      };
      tableEntries.push(entry);
    });
    setAnalyticsData(tableEntries);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      {analyticsData ? (
        <DataGrid
          loading={isLoading}
          pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          rows={analyticsData}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          sx={{
            height: height * 0.75,
            boxShadow: 3,
          }}
        />
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}
