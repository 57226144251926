import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { LeftDrawerWidth } from "./LeftDrawer";
import { AccountCircle } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import Network from "../network";
import Profile from "./Profile";
import { DrawerMenuItems } from "../model";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: LeftDrawerWidth,
    width: `calc(100% - ${LeftDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function TopAppBar({
  open,
  handleDrawerOpen,
  text,
  userData,
  setUserData,
  setSelectedView,
  ...props
}) {
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = async () => {
    handleMenuClose();
    setProfileOpen(true);
  };

  const handleUserManagement = async () => {
    handleMenuClose();
    setSelectedView(DrawerMenuItems.UserManagement);
  };

  const handleSignOut = async () => {
    const res = await Network.Get("auth/sign_out");
    if (res.status === 200) {
      setUserData(null);
    }
    handleMenuClose();
  };

  return (
    <AppBar position="fixed" open={open}>
      <Profile
        open={profileOpen}
        handleClose={() => setProfileOpen(false)}
        userData={userData}
      />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {text}
        </Typography>
        <div>
          <Typography variant="subtitle1" component="span">
            {userData.name}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleUserManagement}>User Management</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;
