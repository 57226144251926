import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { AnalyticsEntryInterface } from "../model";
import AnalyticsDataGrid from "../components/AnalyticsDataGrid";

interface UsageAnalyticsTableEntryInterface {
  id: number;
  license_key: string;
  action: string;
  mode: string;
  locale: string;
  game: string;
  date: string;
  time: string;
}

const columns: GridColDef[] = [
  { field: "license_key", headerName: "License Key", minWidth: 240 },
  { field: "action", headerName: "Action Type", minWidth: 190 },
  { field: "mode", headerName: "Launch Mode", minWidth: 190 },
  { field: "locale", headerName: "Locale", minWidth: 190 },
  { field: "game", headerName: "Game Name", minWidth: 190 },
  {
    field: "time",
    headerName: "Time",
    description: "Local time of data received by server",
    minWidth: 110,
  },
  {
    field: "date",
    headerName: "Date",
    description: "Local date of data received by server",
  },
];

function displayItem(item: AnalyticsEntryInterface): boolean {
  return (
    item.data.data.action !== "rejoyce serial number" &&
    item.data.data.action !== "rejoyce firmware version"
  );
}

export default function UsageAnalyticsTable() {
  const [analyticsData, setAnalyticsData] = React.useState<
    UsageAnalyticsTableEntryInterface[] | null
  >(null);

  const formatPageDataOnLoad = (items: AnalyticsEntryInterface[]) => {
    let counter = 1;
    let tableEntries: UsageAnalyticsTableEntryInterface[] = [];
    items
      .filter((item) => displayItem(item))
      .forEach((item) => {
        let date = new Date(item.data.timestamp);
        let entry: UsageAnalyticsTableEntryInterface = {
          id: counter++,
          license_key: item.data.license_key,
          action: item.data.data.action,
          mode: item.data.data.data
            ? item.data.data.data.mode
              ? item.data.data.data.mode
              : item.data.data.data.newMode
            : "",
          locale: item.data.data.data ? item.data.data.data.locale : "",
          game: item.data.data.data ? item.data.data.data.game : "",
          date: date.toLocaleDateString(),
          time: date.toLocaleTimeString(),
        };
        tableEntries.push(entry);
      });
    setAnalyticsData(tableEntries);
  };

  return (
    <AnalyticsDataGrid
      analyticsData={analyticsData}
      formatPageDataOnLoad={formatPageDataOnLoad}
      columns={columns}
    />
  );
}
